import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Toast } from 'ui';

import CommentsList from './comments-list';
import GET_COMMENTS, {
  GetCommentsQuery,
  GetCommentsQueryVars,
} from '../../graphql/get-comments';
import { removeEmptyProperties } from '../../utils/removeEmptyProperties';

export const enum SortType {
  POPULAR = 'POPULAR',
  LAST = 'LAST',
  ORDER = 'ORDER',
}

const CommentsListContainer: React.FC<{
  tournamentId?: string;
  eventId?: string;
  newsId?: string;
  commentId?: string;
  lastCommentId?: string;
}> = ({ tournamentId, eventId, newsId, commentId, lastCommentId }) => {
  const router = useRouter();
  const sort = commentId
    ? SortType.ORDER
    : (router.query.sort as SortType) || SortType.LAST;

  const setSort = (sort: SortType) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, sort },
      },
      undefined,
      { shallow: true }
    );
  };

  const { data, error, fetchMore, refetch } = useQuery<
    GetCommentsQuery,
    GetCommentsQueryVars
  >(GET_COMMENTS, {
    variables: {
      tournamentId,
      eventId,
      newsId,
      commentId,
      lastCommentId,
      sort,
    },
  });

  useEffect(() => {
    refetch();
  }, [tournamentId, eventId]);

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
      return;
    }

    if (data?.getComments.__typename === 'Error') {
      Toast.error(data.getComments.error);
    }
  }, [data, error]);

  const loadMore = (lastCommentId: string) => {
    fetchMore({
      variables: {
        ...removeEmptyProperties({
          tournamentId,
          eventId,
          newsId,
          commentId,
          lastCommentId,
          sort,
        }),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (
          fetchMoreResult.getComments.__typename !== 'GetComments' ||
          prev.getComments.__typename !== 'GetComments'
        ) {
          return prev;
        }

        return {
          ...prev,
          getComments: {
            ...prev.getComments,
            totalCount: fetchMoreResult.getComments.totalCount,
            comments: [
              ...prev.getComments.comments,
              ...fetchMoreResult.getComments.comments,
            ],
          },
        };
      },
    });
  };

  const updateCommentsData = () => {
    if (data?.getComments.__typename !== 'GetComments') return;

    if (data.getComments.level === 1) {
      refetch();
    }
  };

  if (data?.getComments.__typename !== 'GetComments') return null;

  return (
    <CommentsList
      sort={sort}
      setSort={setSort}
      loadMore={loadMore}
      updateCommentsData={updateCommentsData}
      commentsData={data.getComments}
      answerVariables={{
        tournamentId: tournamentId,
        eventId: eventId,
        newsId: newsId,
        commentId: commentId,
      }}
    />
  );
};

export default CommentsListContainer;
